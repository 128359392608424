import React, { useCallback, useEffect, useState } from "react";
import { Translations } from "../translations/translations";
import { daTranslations } from "../translations/da.translations";

type ReplaceStrings = Record<string, string | number>;
type ReplaceFunction = (replaceStrings?: ReplaceStrings) => string;

const EMPTY_STRINGS = recurse<ReplaceFunction>(daTranslations, () => (_) => "");

interface TranslationHook {
    t: Translations<ReplaceFunction>;
}

const TranslationContext = React.createContext<TranslationHook>({
    t: EMPTY_STRINGS,
});

type Props = {
    children: React.ReactNode;
};

function recurse<T>(initial, updateFunction): Translations<T> {
    const result = {};

    for (const prop in initial) {
        if ({}.hasOwnProperty.call(initial, prop)) {
            result[prop] = initial[prop];

            if (typeof initial[prop] === "object") {
                result[prop] = recurse(initial[prop], updateFunction);
            } else {
                result[prop] = updateFunction(initial[prop]);
            }
        }
    }

    return result as Translations<T>;
}

export const TranslationProvider = ({ children }: Props): JSX.Element => {
    const [translationsObject, setTranslationsObject] =
        useState<Translations<ReplaceFunction>>(EMPTY_STRINGS);

    const replaceInString = useCallback(
        (
            string: string | undefined,
            replaceStrings?: Record<string, string | number>,
        ): string => {
            if (!replaceStrings) {
                return string;
            }

            const regex = new RegExp(
                "{{" + Object.keys(replaceStrings).join("}}|{{") + "}}",
                "g",
            );

            return string.replace(
                regex,
                (match) => String(replaceStrings[match.slice(2, -2)]) || "",
            );
        },
        [],
    );

    useEffect(() => {
        setTranslationsObject(
            recurse(
                daTranslations,
                (value: string) => (replaceString: ReplaceStrings) =>
                    replaceInString(value, replaceString),
            ),
        );
    }, [replaceInString]);

    return (
        <TranslationContext.Provider
            value={{
                t: translationsObject,
            }}
        >
            {children}
        </TranslationContext.Provider>
    );
};

export const useTranslation = (): TranslationHook =>
    React.useContext(TranslationContext);
