import { SiteVariant } from "@interfaces/siteVariant.interface";

export const SITE_VARIANTS: SiteVariant[] = [
    {
        alias: "danish-agro",
        name: "Danish Agro",
        phone: "72158000",
        email: "kundeservice@danishagro.dk",
        introVideo:
            "https://stcommerceprod.blob.core.windows.net/deliveryplacements/DA-guide.mp4",
        areaNeed: "https://danishagro.dk/tilkoerselsforhold",
        gdprLink: "https://danishagro.dk/legal/privatlivs-og-cookiepolitik",
        keys: {
            googleMaps: "AIzaSyCBqY9mpYO6gk6YIWTeJbIev-lFSux2WOM",
            gtm: "GTM-W6D4R4M",
        },
    },
    {
        alias: "hedegaard",
        name: "Hedegaard",
        phone: "99361717",
        email: "bestil@hedegaard-as.dk",
        introVideo:
            "https://stcommerceprod.blob.core.windows.net/deliveryplacements/Hedegaard-guide.mp4",
        areaNeed: "https://hedegaard-as.dk/tilkoerselsforhold",
        gdprLink: "https://hedegaard-as.dk/legal/cookie-og-persondatapolitik",
        keys: {
            googleMaps: "AIzaSyD61C7tlx58jJ6FA6IqYOdE_wyWP_ZjVFA",
            gtm: "GTM-PDZSKKX",
        },
    },
];
