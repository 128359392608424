import React from "react";
import { AddAddress, Address } from "@interfaces/address.interface";
import { Location } from "@interfaces/location.interface";
import { deleteFetch, getFetch, postFetch } from "@helpers/api.helper";
import { useAppData } from "./appData.hook";

interface ApiHook {
    addresses: {
        getAll: (uid: string) => Promise<Address[]>;
        add: (uid: string, data: AddAddress) => Promise<unknown>;
        delete: (uid: string, addressId: Address["id"]) => Promise<unknown>;
    };
    customers: {
        complete: (uid: string) => Promise<unknown>;
        confirmGdpr: (uid: string) => Promise<unknown>;
    };
    locations: {
        getAll: (uid: string, addressId: Address["id"]) => Promise<Location[]>;
        add: (
            uid: string,
            addressId: Address["id"],
            data: Location,
        ) => Promise<Location>;
        delete: (
            uid: string,
            addressId: Address["id"],
            locationId: Location["id"],
        ) => Promise<unknown>;
    };
}

const ApiContext = React.createContext<ApiHook>({
    addresses: {
        getAll: () => Promise.reject(),
        add: () => Promise.reject(),
        delete: () => Promise.reject(),
    },
    customers: {
        complete: () => Promise.reject(),
        confirmGdpr: () => Promise.reject(),
    },
    locations: {
        getAll: () => Promise.reject(),
        add: () => Promise.reject(),
        delete: () => Promise.reject(),
    },
});

type Props = {
    children: React.ReactNode;
};

export const ApiProvider = ({ children }: Props): JSX.Element => {
    const { apiGatewayUrl } = useAppData();

    const addresses = {
        getAll: (uid: string) => getFetch(apiGatewayUrl, `${uid}/address`),
        add: (uid: string, data: AddAddress) =>
            postFetch(apiGatewayUrl, `${uid}/address`, data),
        delete: (uid: string, addressId: Address["id"]) =>
            deleteFetch(apiGatewayUrl, `${uid}/address`, addressId),
    };

    const customers = {
        complete: (uid: string) =>
            getFetch(
                apiGatewayUrl,
                `${uid}/customer/complete`,
                undefined,
                true,
            ),
        confirmGdpr: (uid: string) =>
            postFetch(
                apiGatewayUrl,
                `${uid}/customer/confirmprivacypolicy`,
                {},
                true,
            ),
    };

    const locations = {
        getAll: (uid: string, addressId: Address["id"]) =>
            getFetch(apiGatewayUrl, `${uid}/location/${addressId}`),
        add: (uid: string, addressId: Address["id"], data: Location) =>
            postFetch<Location>(
                apiGatewayUrl,
                `${uid}/location/${addressId}`,
                data,
            ),
        delete: (uid: string, addressId: Address["id"], locationId: number) =>
            deleteFetch(
                apiGatewayUrl,
                `${uid}/location/${addressId}`,
                locationId,
            ),
    };

    return (
        <ApiContext.Provider
            value={{
                addresses,
                locations,
                customers,
            }}
        >
            {children}
        </ApiContext.Provider>
    );
};

export const useApi = (): ApiHook => React.useContext(ApiContext);
