import { Translations } from "./translations";

export const daTranslations: Translations<string> = {
    siteHeader: {
        goToFrontpage: "Gå til forsiden",
        cvr: "CVR: {{cvrNumber}}",
    },
    frontpage: {
        title: "Leveringspunkter",
        body: `
<p>Vi vil nu bede dig opdatere, hvor du ønsker at få varer fra {{company}} leveret på din bedrift. Det gør vi for at sikre, at leveringerne kan ske bedst muligt for både dig og {{company}}.</p>
<p>Tak fordi du vil hjælpe med at få opdateret leveringspunkterne hos dig.</p>
<p>Først vil vi bede dig bekræfte at nedenstående adresser stadig er rigtige.</p>
<ol>
    <li>Tilføj og fjern adresser på det givne CVR-nummer (Har du flere CVR numre, vil du modtage en e-mail for hvert CVR nummer).</li>
    <li>Angiv leveringspunkter for hver enkelt adresse.</li>
</ol>
<p>Du vil modtage en kvitteringsmail efter indsendelse.</p>
        `,
        addresses: {
            addDeliveryPointsText: "Angiv leveringspunkter for denne adresse",
            addPointsButtonTitle: "Start",
            deleteAddressLink: "Slet adresse",
            editPointsButtonTitle: "Ret placeringer",
            missingInformation: "Mangler oplysninger",
            completedInformation: "Alle oplysninger angivet",
            nPoints: "{{n}} placeringer",
            addNew: "Tilføj ny adresse",
            addModal: {
                title: "Opret ny leveringsadresse",
                label: "Indtast adresse",
                submit: "Opret adresse",
            },
            deleteModal: {
                title: "Slet leveringsadresse",
                text: `
<p>
    Du er ved at slette adressen {{addressText}}, {{zipText}} og dens eventuelle leveringspunkter.
</p>
<p>
    Denne handling kan ikke fortrydes.
</p>`,
            },
            confirmAddressesInfo: {
                title: "Bekræft informationer",
                text: `
                <p>
                    Leveringspunkter og adresser kan ikke slettes eller redigeres efter indsendelse.<br><br>Eventuelle ændringer til disse skal ske ved kontakt til kundeservice.
                </p>`,
            },
        },
        bottomFrontpageBox: {
            text: "Når du har opdateret alle relevante leveringspunkter på dine adresser beder vi dig bekræfte ved at indsende oplysningerne:\n",
            button: "Indsend",
        },
    },
    gdpr: {
        title: "Privatlivspolitik",
        text: `
<p>
    For at kunne registrere dine leveringspunkter kræver det at du accepterer vores privatlivspolitik før du kan gå videre.
    <br><br>
    <a href="{{gdprLink}}" target="_blank">Læs mere om vores privatlivspolitik</a>
    </p>
        `,
        label: "Jeg har læst og accepterer privatlivspolitik",
    },
    addressPage: {
        back: "Tilbage til adresseoverblik",
        info: {
            introButton: "Vis introduktion igen",
            text: `
<p>
    Hvis du er det mindste i tvivl eller har spørgsmål, er du velkommen til at kontakte kundeservice<br />
    tlf: {{phone}} / <a href='mailto:{{email}}'>{{email}}</a>. Så guider vi dig igennem.
</p>
            `,
        },
        picker: {
            places: {
                title: "Leveringspunkter",
                description: `
<p>
    Her vises alle dine punkter på tværs af produkttyper. Tryk på kortet under den enkelte produkttype for at oprette
    et leveringspunkt.
</p>
                `,
            },
            types: {
                description: `
<p>Vælg produkttype og klik derefter på kortet for at markere det enkelte leveringspunkt:</p>
                `,
                mapOverlay: `
<p>Klik på kortet for at oprette et leveringspunkt. Vær så nøjagtig som muligt</p>
                `,
            },
            infoWindow: {
                deleteModal: {
                    title: "Slet leveringspunkt",
                    text: `
<p>
    Du er ved at slette et leveringspunkt.
</p>
<p>
    Denne handling kan ikke fortrydes.
</p>`,
                },
            },
            successWindow: {
                title: "Placering er nu gemt",
                text: "Klik på kortet for at oprette yderligere punkter.",
            },
            selectTypeModal: {
                title: "Vælg produkttype",
                text: "Du skal vælge en produkttype, før du kan oprette et leveringspunkt.",
            },
        },
        introModal: {
            title: "Sådan gør du",
            body: `
<p>
    Nu skal du oprette leveringspunkter for de relevante produkttyper på adressen. Du får præsenteret alle produktkategorier,
    men hvis du f.eks. ikke får leveret "Flydende råvarer" på den adresse du er ved at håndtere, undlader du blot at
    oprette leveringspunkter herunder.
</p>
<p>
    Du følger blot disse steps:
</p>
            `,
            steps: `
<p>
    1. Først <b>vælger du en produkttype</b> henover kortet
</p>
<p>
    2. Derefter <b>trykker du på kortet</b> hvor f.eks. din silo befinder sig
</p>
<p>
    3. <b>Udfyld informationerne</b> om placeringen
</p>
<p>
    4. <b>Opret resten</b> af de steder {{company}} skal levere forskellige produkttyper
</p>
            `,
            helper: "OBS: Vær opmærksom på at der kan være flere leveringsmåder inde under de enkelte produkttyper, f.eks. under Foder og råvarer kan du både vælge indblæsning, aftip og sække/paller. Det ene udelukker ikke det andet, så hvis du f.eks. både har en silo og en grav (aftip) hvor vi skal levere, skal du lave to leveringspunkter, én til siloen og én til graven.",
            button: "Gå i gang",
        },
        reminderModal: {
            title: "Har du fået det hele med?",
            textTop: `
<p>Vi kan se, at du <u>ikke</u> har registreret, hvor vi skal levere:</p>
            `,
            textBottom: `
<p>Hvis disse har relevans, bedes du oprette leveringspunkter for disse ved at trykke på "Gå tilbage".</p>
            `,
            cancel: "Gå tilbage",
            submit: "Ikke relevant for adresse",
        },
        footer: {
            text: "Når du har opdateret alle relevante leveringspunkter på denne adresse, kan du komme tilbage til adresseoverblikket her",
            button: "Gå til adresseoverblik",
        },
    },
    completePage: {
        title: "Tak for hjælpen",
        body: `
<p>
    Din indsats med at placere de forskellige leveringstyper, betyder at {{company}}
    nu har mulighed for at sikre en mere effektiv levering af dine produkter.
</p>
<p>
    Skulle du have spørgsmål til brugen af placeringsværktøjet er du velkommen til at
    kontakte kundeservice på tlf.&nbsp;<a href="tel:{{phone}}">{{phone}}</a> eller
    mail:&nbsp;<a href="mailto:{{email}}">{{email}}</a>.
</p>
        `,
    },
    locationEditor: {
        steps: {
            placement: {
                title: "Opret leveringspunkt på adresse",
                type: {
                    label: "Placeringstype",
                },
                number: {
                    label: "Nummer",
                    tooltip:
                        "Det er en fordel for vores chauffører, hvis placeringen er nummereret. Hvis ikke du selv har et nummer-klistermærke, har vores chauffører nogle med i bilen. Du skal blot spørge dem.",
                },
            },
            driveway: {
                title: "Tilkørselsforhold for placering",
                wagonType: {
                    label: "Tilkørsel mulig med vogntype",
                    tooltip:
                        'Med "vogntyper" mener vi hvilket transportmiddel, der er plads til på placeringen. Du kan klikke på "Arealbehov" linket og læse mere om hvor meget plads de forskellige vogntyper kræver.',
                    areaNeed: "Arealbehov",
                },
                tippingType: "Aftip type",
                comment: "Bemærkning",
            },
            siloCapacity: {
                title: "Detaljer for placering",
                kilos: "Silokapacitet (kg)",
                cubicMeters: "Silokapacitet (m3)",
                liters: "Tankkapacitet (liter)",
            },
            deliveryTime: {
                title: "Leveringstidpunkt",
                question: {
                    bigBagsSeeds:
                        "Må vi levere såsæd så snart det er produceret og planlagt til udkørsel?",
                    bigBagsFertilizer:
                        "Må vi levere gødning så snart det er produceret og planlagt til udkørsel?",
                    bigBagsSeedsFertilizer:
                        "Må vi levere gødning og såsæd så snart det er produceret og planlagt til udkørsel?",
                    liquidFertilizer:
                        "Må vi levere gødning så snart det er produceret og planlagt til udkørsel?",
                },
                yes: "Ja (Der afsendes SMS to dage inden vi leverer)",
                no: "Nej, ring og aftal tidspunkt",
            },
            beaconSubscription: {
                title: "Pejleabonnement",
                question: "Er der en pejler på tanken?",
                yes: "Ja",
                no: "Nej",
            },
            contactPerson: {
                title: "Kontaktperson for placering",
                contactName: "Navn",
                contactPhone: "Telefon",
            },
        },
        buttons: {
            back: "Tilbage",
            next: "Næste",
            save: "Gem",
        },
    },
    common: {
        ok: "OK",
        cancel: "Annuller",
        close: "Luk",
        delete: "Slet",
        edit: "Rediger",
        select: "Vælg",
        required: "Påkrævet",
        confirm: "Bekræft",
    },
};
