import React from "react";
import { Container } from "@atoms/Container/Container.component";
import { useAppData } from "@hooks/appData.hook";
import { BasicLink } from "@atoms/BasicLink/BasicLink.component";
import { useTranslation } from "@hooks/translation.hook";
import { ReactComponent as LogoSvg } from "../../../assets/svg/danish-agro-logo.svg";
import { ReactComponent as HedegaardLogo } from "../../../assets/svg/hedegaard-logo.svg";
import S from "./SiteHeader.module.scss";

export const SiteHeader = (): JSX.Element => {
    const { t } = useTranslation();
    const { siteVariant, cvrNumber } = useAppData();

    const frontpageLink = window.location.pathname.match(/^\/[^/]*\/?/g)[0];

    return (
        <header className={S.header}>
            <Container className={S.container}>
                <div className={S.logo}>
                    <BasicLink
                        href={frontpageLink}
                        className={S.logoLink}
                        conventionalRouting={true}
                    >
                        {siteVariant.alias === "danish-agro" && <LogoSvg />}
                        {siteVariant.alias === "hedegaard" && <HedegaardLogo />}
                        <span className="visually-hidden">
                            {t.siteHeader.goToFrontpage()}
                        </span>
                    </BasicLink>
                </div>
                <div className={S.customerNumber}>
                    {cvrNumber && t.siteHeader.cvr({ cvrNumber })}
                </div>
            </Container>
        </header>
    );
};
