type QueryObject = Record<string, any>;

const getUrl = (
    apiGatewayUrl: string,
    path: string,
    parameters?: QueryObject,
): string => {
    const allParameters: QueryObject = { ...parameters };

    const query = Object.keys(allParameters)
        .map((key) =>
            typeof allParameters[key] === "undefined" ||
            allParameters[key] === null
                ? ""
                : Array.isArray(allParameters[key])
                ? allParameters[key]
                      .map((arrayValue) => `${key}=${arrayValue}`)
                      .join("&")
                : `${key}=${
                      typeof allParameters[key] === "object"
                          ? JSON.stringify(allParameters[key])
                          : String(allParameters[key])
                  }`,
        )
        .join("&")
        .replace(/&+/g, "&")
        .replace(/(&|\?&?)$/, "");

    return (
        apiGatewayUrl +
        "/" +
        path.replace(/^\/+/, "").replace(/\/+$/, "") +
        (query ? `?${query}` : "")
    );
};

export const getFetch = (
    apiGatewayUrl: string,
    path: string,
    parameters?: QueryObject,
    expectText?: boolean,
) =>
    fetch(getUrl(apiGatewayUrl, path, parameters))
        .then((response) => (expectText ? response.text() : response.json()))
        .then((response) => {
            if (response.errors) {
                throw response;
            }
            return response;
        });

export const postFetch = <T>(
    apiGatewayUrl: string,
    path: string,
    parameters: QueryObject | string | number | boolean,
    expectText?: boolean,
) =>
    fetch(getUrl(apiGatewayUrl, path), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body:
            typeof parameters === "object"
                ? JSON.stringify(parameters)
                : typeof parameters !== "string"
                ? parameters.toString()
                : parameters,
    }).then((response) => (expectText ? response.text() : response.json()));

export const deleteFetch = (
    apiGatewayUrl: string,
    path: string,
    parameters: QueryObject | string | number | boolean,
) =>
    fetch(getUrl(apiGatewayUrl, path), {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        body:
            typeof parameters === "object"
                ? JSON.stringify(parameters)
                : typeof parameters !== "string"
                ? parameters.toString()
                : parameters,
    });
