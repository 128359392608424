import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import { animated, useSpring, useTransition } from "react-spring";
import { getVimeoId, getYouTubeId } from "@baggie/string";
import { useMeasure } from "react-use";
import { blurActiveElement } from "@helpers/blurActiveElement.helper";
import { cn } from "@helpers/classNames.helper";

export enum ModalSize {
    XS = 360,
    SM = 480,
    MD = 580,
    LG = 720,
    XL = 840,
    XXL = 1000,
    XXXL = 1200,
}

type ContentType = React.ReactNode | string;

export interface ModalOptions {
    size?: ModalSize;
    shouldCloseOnOverlayClick?: boolean;
    onClose?: () => void;
}

interface ModalHook {
    showModal: (content: ContentType, options?: ModalOptions) => void;
    closeModal: () => void;
    isModalShowing: boolean;
    scrollToTop: () => void;
}

const ModalContext = React.createContext<ModalHook>({
    showModal: () => null,
    closeModal: () => null,
    isModalShowing: false,
    scrollToTop: () => null,
});

type Props = {
    children: React.ReactNode;
};

ReactModal.setAppElement(document.body);

export const ModalProvider = ({ children }: Props): JSX.Element => {
    const [shouldCloseOnOverlayClick, setShouldCloseOnOverlayClick] =
        useState(true);
    const [content, setContent] = useState<ContentType>();
    const [isVideo, setIsVideo] = useState(false);
    const [isShowing, setIsShowing] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [size, setSize] = useState<ModalSize>(ModalSize.SM);
    const onCloseCallback = useRef<() => void>(null);
    const heightChangeCount = useRef(0);

    const contentWrapperRef = useRef<HTMLDivElement>(null);
    const [contentRef, { height }] = useMeasure<HTMLDivElement>();

    const transition = useTransition(isShowing, {
        from: { opacity: 0, transform: "translate3d(0, -30px, 0)" },
        enter: { opacity: 1, transform: "translate3d(0, 0px, 0)" },
        leave: { opacity: 0, transform: "translate3d(0, 30px, 0)" },
        delay: isShowing ? 100 : 0,
        config: { friction: 24 },
        onDestroyed: () => {
            if (!isShowing) {
                setContent(undefined);
                heightChangeCount.current = 0;
                // When the modal closes, focus returns to the element that opened the modal.
                // This is good - but we don't want to draw attention to this element,
                // so let's blur it to remove the outline.
                setTimeout(() => blurActiveElement(), 0);
            }
        },
    });

    const contentHeight = useSpring({
        config: { friction: 24 },
        height: `${height}px`,
        immediate: heightChangeCount.current === 0,
        onRest: () =>
            (heightChangeCount.current = heightChangeCount.current + 1),
    });

    const showModal = useCallback(
        (content: ContentType, options?: ModalOptions) => {
            const { shouldCloseOnOverlayClick = true, onClose } = options || {};
            let returnContent = content;
            let returnVideo = false;

            setShouldCloseOnOverlayClick(shouldCloseOnOverlayClick);

            if (typeof content === "string" && content.startsWith("http")) {
                const youTubeId = getYouTubeId(content);
                const vimeoId = getVimeoId(content);

                if (youTubeId) {
                    returnVideo = true;
                    returnContent = `<iframe src="https://www.youtube-nocookie.com/embed/${youTubeId}?autoplay=1&rel=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
                } else if (vimeoId) {
                    returnVideo = true;
                    returnContent = `<iframe src="https://player.vimeo.com/video/${vimeoId}?autoplay=1&color=ffffff" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe><script src="https://player.vimeo.com/api/player.js"></script>`;
                }
            }

            setIsVideo(returnVideo);
            setSize(options?.size || ModalSize.SM);
            if (typeof onClose === "function") {
                onCloseCallback.current = onClose;
            }
            setContent(returnContent);
        },
        [],
    );

    const closeModal = useCallback(() => {
        setIsShowing(false);
        setShowOverlay(false);

        if (onCloseCallback.current) {
            onCloseCallback.current();
            onCloseCallback.current = undefined;
        }
    }, [setIsShowing, setShowOverlay, onCloseCallback]);

    const scrollToTop = useCallback(() => {
        contentWrapperRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }, [contentWrapperRef]);

    useEffect(() => {
        if (isShowing) {
            setShowOverlay(true);
        }
    }, [isShowing]);

    useEffect(() => {
        if (content) {
            setIsShowing(true);
        }
    }, [content]);

    useEffect(
        () =>
            ReactModal.setAppElement(
                document.getElementById("__next") ||
                    document.getElementById("root"),
            ),
        [],
    );

    return (
        <ModalContext.Provider
            value={{
                showModal,
                closeModal,
                isModalShowing: typeof content !== "undefined",
                scrollToTop,
            }}
        >
            {children}

            <ReactModal
                isOpen={typeof content !== "undefined"}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                shouldCloseOnEsc={shouldCloseOnOverlayClick}
                overlayClassName="modal-container"
                className={isVideo ? "modal-video-content" : "modal-content"}
                style={{
                    content: {
                        maxWidth: `${size}px`,
                    },
                }}
                overlayElement={(props, contentEl) => (
                    <div {...props}>
                        <div
                            className={cn(
                                "modal-overlay",
                                showOverlay && "modal-overlay--show",
                            )}
                        />
                        {contentEl}
                    </div>
                )}
            >
                {transition(({ transform, opacity }, state) =>
                    state ? (
                        <animated.div style={{ transform, opacity }}>
                            <animated.div
                                ref={contentWrapperRef}
                                className="modal-content-inside"
                                style={contentHeight}
                            >
                                <div ref={contentRef}>
                                    {isVideo && typeof content === "string" ? (
                                        <div
                                            className="video-container"
                                            dangerouslySetInnerHTML={{
                                                __html: content,
                                            }}
                                        />
                                    ) : (
                                        content
                                    )}
                                </div>
                            </animated.div>
                        </animated.div>
                    ) : null,
                )}
            </ReactModal>
        </ModalContext.Provider>
    );
};

export const useModal = (): ModalHook => React.useContext(ModalContext);
