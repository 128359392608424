import React, { ForwardedRef } from "react";
import { cn } from "@helpers/classNames.helper";
import S from "./Container.module.scss";

interface ContainerProps {
    children: React.ReactNode;
    noVerticalPadding?: boolean;
    noRightPadding?: boolean;
    className?: string;
}

export const Container = React.forwardRef(
    (
        {
            children,
            noVerticalPadding,
            noRightPadding,
            className,
        }: ContainerProps,
        ref: ForwardedRef<HTMLDivElement>,
    ): JSX.Element => (
        <div
            className={cn(
                S.container,
                noVerticalPadding && S.noVerticalPadding,
                noRightPadding && S.noRightPadding,
                className,
            )}
            ref={ref}
        >
            {children}
        </div>
    ),
);

Container.displayName = "Container";
