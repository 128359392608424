import "./styles/global/main.scss";
import React, { useEffect, Suspense, lazy } from "react";
import {
    BrowserRouter as Router,
    Navigate,
    Routes,
    Route,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { DetectInputMethod } from "@baggie/detection";
import { GoogleTagManager } from "@atoms/GoogleTagManager/GoogleTagManager.component";
import { SiteHeader } from "@organisms/SiteHeader/SiteHeader.component";
import { AppDataProvider, useAppData } from "@hooks/appData.hook";
import { ScreenSizeProvider, useScreenSize } from "@hooks/screenSize.hook";
import { ModalProvider, useModal } from "@hooks/modal.hook";
import { TranslationProvider } from "@hooks/translation.hook";
import { ApiProvider } from "@hooks/api.hook";
import { isDevMode } from "@helpers/isDevMode.helper";
import { TEST_USER_ID } from "@constants/test.constants";

let setViewportThrottle: number;

const setViewport = (): void => {
    window.clearTimeout(setViewportThrottle);
    setViewportThrottle = window.setTimeout(() => {
        if (
            !document.activeElement ||
            (document.activeElement.tagName !== "INPUT" &&
                document.activeElement.tagName !== "TEXTAREA")
        ) {
            const viewHeight = window.innerHeight;
            let viewport = document.querySelector("meta[name=viewport]");
            if (!viewport) {
                viewport = document.createElement("meta");
                viewport.setAttribute("name", "viewport");
            }
            viewport.setAttribute(
                "content",
                `height=${viewHeight}, width=device-width, initial-scale=1.0`,
            );
        }
    }, 300);
};

const GlobalLogic = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const { siteVariant } = useAppData();
    const { isScrollLocked } = useScreenSize();
    const { isModalShowing } = useModal();

    useEffect(() => {
        if (isModalShowing || isScrollLocked) {
            // Enable scroll lock
            document.documentElement.style.overflow = "hidden";
            document.documentElement.classList.add("scroll-locked");
        } else {
            // Disable scroll lock
            document.documentElement.style.overflow = "";
            document.documentElement.classList.remove("scroll-locked");
        }
    }, [isModalShowing, isScrollLocked]);

    useEffect(() => {
        setViewport();
        const inputMethod = new DetectInputMethod({
            continuousDetection: true,
        });
        window.addEventListener("resize", setViewport);

        return () => {
            inputMethod.unbind();
            window.removeEventListener("resize", setViewport);
        };
    }, []);

    return (
        <>
            <Helmet>
                <meta name="description" content="" />
                <body className={siteVariant.alias} />
            </Helmet>
            <GoogleTagManager gtmKey={siteVariant.keys.gtm} />
            {children}
        </>
    );
};

export const ContextWrapper = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => (
    <AppDataProvider>
        <ApiProvider>
            <ScreenSizeProvider>
                <TranslationProvider>
                    <ModalProvider>
                        <GlobalLogic>{children}</GlobalLogic>
                    </ModalProvider>
                </TranslationProvider>
            </ScreenSizeProvider>
        </ApiProvider>
    </AppDataProvider>
);

const Frontpage = lazy(
    () =>
        import(
            /* webpackChunkName: "Frontpage" */ "@pages/Frontpage/Frontpage.component"
        ),
);
const AddressPage = lazy(
    () =>
        import(
            /* webpackChunkName: "AddressPage" */ "@pages/Address/Address.component"
        ),
);
const CompletePage = lazy(
    () =>
        import(
            /* webpackChunkName: "CompletePage" */ "@pages/Complete/Complete.component"
        ),
);
const NotFoundPage = lazy(
    () =>
        import(
            /* webpackChunkName: "NotFoundPage" */ "@pages/NotFound/NotFound.component"
        ),
);

const RoutesComponent = () => (
    <>
        <SiteHeader />
        <Suspense fallback={<div />}>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Navigate
                            to={isDevMode ? `/${TEST_USER_ID}` : "not-found"}
                        />
                    }
                />
                <Route path="/:uid" element={<Frontpage />} />
                <Route
                    path="/:uid/address/:addressId"
                    element={<AddressPage />}
                />
                <Route path="/:uid/complete" element={<CompletePage />} />
                <Route path="not-found" element={<NotFoundPage />} />
            </Routes>
        </Suspense>
    </>
);

export const App = () => (
    <Router>
        <ContextWrapper>
            <RoutesComponent />
        </ContextWrapper>
    </Router>
);
