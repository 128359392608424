import React, { useEffect, useRef, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { Libraries } from "@googlemaps/js-api-loader";
import { isDevMode } from "@helpers/isDevMode.helper";
import { TEST_CVR } from "@constants/test.constants";
import { SiteVariant } from "@interfaces/siteVariant.interface";
import { SITE_VARIANTS } from "@constants/variantData.constants";

const DEFAULT_API_URL =
    "https://app-deliverylocations-prod.azurewebsites.net/api";

const GOOGLE_MAPS_LIBRARIES: Libraries = ["places"];

interface AppDataHook {
    mapIsLoaded: boolean;
    apiGatewayUrl: string;
    cvrNumber: number;
    siteVariant: SiteVariant;
    hasAcceptedPrivacyPolicy: boolean;
    setHasAcceptedPrivacyPolicy: (state: boolean) => void;
}

const AppDataContext = React.createContext<AppDataHook>({
    mapIsLoaded: false,
    apiGatewayUrl: "",
    cvrNumber: TEST_CVR,
    siteVariant: SITE_VARIANTS[0],
    hasAcceptedPrivacyPolicy: globalThis.hasAcceptedPrivacyPolicy,
    setHasAcceptedPrivacyPolicy: () => null,
});

type Props = {
    children: React.ReactNode;
};

export const AppDataProvider = ({ children }: Props): JSX.Element => {
    const [hasAcceptedPrivacyPolicy, setHasAcceptedPrivacyPolicy] = useState(
        globalThis.hasAcceptedPrivacyPolicy,
    );
    const [cvrNumber, setCvrNumber] = useState<number>();
    const siteVariant = useRef<SiteVariant>(
        SITE_VARIANTS.find(({ alias }) => alias === globalThis.siteVariant) ||
            SITE_VARIANTS[0],
    ).current;
    const { isLoaded: mapIsLoaded } = useJsApiLoader({
        libraries: GOOGLE_MAPS_LIBRARIES,
        googleMapsApiKey: siteVariant.keys.googleMaps,
    });

    useEffect(() => {
        const cvr = globalThis.cvr || (isDevMode ? TEST_CVR : undefined);
        if (cvr) {
            setCvrNumber(cvr);
        }
    }, []);

    return (
        <AppDataContext.Provider
            value={{
                mapIsLoaded,
                apiGatewayUrl: globalThis.apiUrl || DEFAULT_API_URL,
                cvrNumber,
                siteVariant,
                hasAcceptedPrivacyPolicy,
                setHasAcceptedPrivacyPolicy,
            }}
        >
            {children}
        </AppDataContext.Provider>
    );
};

export const useAppData = (): AppDataHook => React.useContext(AppDataContext);
