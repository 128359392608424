import React, { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

export enum SCREEN_SIZES {
    XXS = 1,
    XS = 512,
    SM = 768,
    MD = 992,
    LG = 1220,
    XL = 1540,
    XXL = 1840,
}

interface ScreenSizeHook {
    screenSize: SCREEN_SIZES;
    isMobile: boolean;
    isMobileOrTablet: boolean;
    isScrollLocked: boolean;
    toggleScrollLock: (state?: boolean) => void;
    scrollLockOffset: number;
}

const ScreenSizeContext = React.createContext<ScreenSizeHook>({
    screenSize: SCREEN_SIZES.XXS,
    isMobile: true,
    isMobileOrTablet: true,
    isScrollLocked: false,
    toggleScrollLock: () => null,
    scrollLockOffset: 0,
});

type Props = {
    children: React.ReactNode;
};

export const ScreenSizeProvider = ({ children }: Props): JSX.Element => {
    const [isScrollLocked, setIsScrollLocked] = useState(false);
    const [scrollLockOffset, setScrollLockOffset] = useState(0);
    const [screenSize, setScreenSize] = useState(SCREEN_SIZES.XXS);

    const toggleScrollLock = useCallback(
        (state?: boolean) => {
            const newState =
                typeof state === "boolean" ? state : !isScrollLocked;
            setIsScrollLocked(newState);
            setScrollLockOffset(newState ? window.scrollY : 0);
        },
        [isScrollLocked],
    );

    const isMinXs = useMediaQuery({
        query: `(min-width: ${SCREEN_SIZES.XS}px)`,
    });
    const isMinSm = useMediaQuery({
        query: `(min-width: ${SCREEN_SIZES.SM}px)`,
    });
    const isMinMd = useMediaQuery({
        query: `(min-width: ${SCREEN_SIZES.MD}px)`,
    });
    const isMinLg = useMediaQuery({
        query: `(min-width: ${SCREEN_SIZES.LG}px)`,
    });
    const isMinXl = useMediaQuery({
        query: `(min-width: ${SCREEN_SIZES.XL}px)`,
    });
    const isMinXxl = useMediaQuery({
        query: `(min-width: ${SCREEN_SIZES.XXL}px)`,
    });

    useEffect(() => {
        let newScreenSize = SCREEN_SIZES.XXS;
        if (isMinXxl) {
            newScreenSize = SCREEN_SIZES.XXL;
        } else if (isMinXl) {
            newScreenSize = SCREEN_SIZES.XL;
        } else if (isMinLg) {
            newScreenSize = SCREEN_SIZES.LG;
        } else if (isMinMd) {
            newScreenSize = SCREEN_SIZES.MD;
        } else if (isMinSm) {
            newScreenSize = SCREEN_SIZES.SM;
        } else if (isMinXs) {
            newScreenSize = SCREEN_SIZES.XS;
        }
        setScreenSize(newScreenSize);
    }, [isMinXs, isMinSm, isMinMd, isMinLg, isMinXl, isMinXxl]);

    return (
        <ScreenSizeContext.Provider
            value={{
                screenSize,
                isMobile: !isMinSm,
                isMobileOrTablet: !isMinMd,
                isScrollLocked,
                toggleScrollLock,
                scrollLockOffset,
            }}
        >
            {children}
        </ScreenSizeContext.Provider>
    );
};

export const useScreenSize = (): ScreenSizeHook =>
    React.useContext(ScreenSizeContext);
